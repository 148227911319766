import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import video from "../images/video.mp4" // https://www.shutterstock.com/video/clip-30878275-goldendoodle-puppy-running-slow-motion
import webmvideo from "../images/webmvideo.webm"
//import oggvideo from "../images/video.ogg"
import videoimage from "../images/videoimage.png"

const IndexPage = ({ data }) => {
  useEffect(() => {
    const video = document.getElementById("video-background")
    var promise = video.play()

    if (promise !== undefined) {
      promise
        .then(_ => {
          // Autoplay started!
        })
        .catch(error => {
          // Show something in the UI that the video is muted
          video.muted = true
          video.play()
        })
    }
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <div
        id="carouselIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        {/* <ol className="carousel-indicators">
          <li
            data-target="#carouselIndicators"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselIndicators" data-slide-to="1"></li>
          <li data-target="#carouselIndicators" data-slide-to="2"></li>
        </ol> */}
        <div className="carousel-inner" role="listbox">
          <div className="carousel-item active">
            <video
              style={
                {
                  // marginTop: 100,
                }
              }
              poster={videoimage}
              id="video-background"
              autoplay
              loop
              muted
              playsinline
              className="video-background"
            >
              <source src={video} type="video/mp4" />
              <source src={webmvideo} type="video/webm" />
              {/* <source src={oggvideo} type="video/ogg"/> */}
            </video>
            {/*d-none to hide*/}
            <div className="carousel-caption d-md-block">
              <h2
                style={{
                  color: "#cfb574",
                  letterSpacing: 5,
                  fontWeight: 800,
                }}
                className="display-4 text-center d-none d-lg-block"
              >
                Royal Sunny Doodles
              </h2>
              {/* <p className="lead">This is a description for the first slide.</p> */}
            </div>
          </div>

          {/* <div
            className="carousel-item"
            style={{
              height: "100vh",
              background: "no-repeat center center scroll",
              backgroundSize: "cover",
              backgroundImage:
                "url(https://images.unsplash.com/photo-1575859056771-55899424ba14?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80)",
            }}
          >
            <div className="carousel-caption d-none d-md-block">
              <h2 className="display-4">Second Slide</h2>
              <p className="lead">
                This is a description for the second slide.
              </p>
            </div>
          </div> */}

          {/* <div
            className="carousel-item"
            style={{
              height: "100vh",
              background: "no-repeat center center scroll",
              backgroundSize: "cover",
              backgroundImage:
                "url(https://images.unsplash.com/photo-1575859430954-850fee09e27b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80)",
            }}
          >
            <div className="carousel-caption d-none d-md-block">
              <h2 className="display-4">Third Slide</h2>
              <p className="lead">This is a description for the third slide.</p>
            </div>
          </div> */}
        </div>
        {/* <a
          className="carousel-control-prev"
          href="#carouselIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a> */}
      </div>
      <section className="py-5" style={{ backgroundColor: "#f5f5f5" }}>
        <div className="container mx-auto">
          <h1 className="display-8 text-center text-uppercase pb-3">
            They're not just dogs, they're family.
          </h1>
          <p className="lead text-center">
            A dog is not just a pet. A dog is someone who will unconditionally
            love you, keep you company, love you to death, be there on your best
            days, and most importantly be there on your worst days. Having a dog
            is not just about having a pet. Having a dog means having a new
            family memeber. Someone who will always be there for you. Someone
            who will be there through your life's most important moments such as
            graduations, marriages, birthdays, births, promotions and many more
            adventures. A dog is someone who will be excited every day to see
            you. A dog is someone who will try to cheer you up on your worst
            days. A dog is family!
          </p>
        </div>
      </section>
      <section className="py-5">
        <div className="container mx-auto">
          <h1 className="display-8 text-center text-uppercase pb-3">
            Royal Sunny Doodles Perks
          </h1>
          <div className="row d-flex justify-content-around">
            <div className="text-center col-sm-3 py-3">
              <i className="far fa-syringe fa-2x"></i>
              <p>Vaccines</p>
              <small>
                Our puppies will receive their first shot while in our care from
                a vet. All puppies will have health records included.
              </small>
            </div>

            <div className="text-center col-sm-3 py-3">
              <i className="fal fa-user-nurse fa-2x"></i>
              <p>Health Guarantee</p>
              <small>
                Our puppies will recieve their first vet check while in our care
                to make sure they are healthy before going to their new family.
              </small>
            </div>

            <div className="text-center col-sm-3 py-3">
              <i className="far fa-dog-leashed fa-2x"></i>
              <p>Socialized</p>
              <small>
                Our puppies are raised in a family home where they interact with
                kids, teens, and adults since day one.
              </small>
            </div>

            <div className="text-center col-sm-3 py-3">
              <i className="fal fa-gift fa-2x"></i>
              <p>Care package</p>
              <small>
                Each puppy comes with puppy food and a brand new toy.
              </small>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" style={{ backgroundColor: "#f5faff" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h3
                className="display-5 text-center text-uppercase"
                style={{ color: "#cab683" }}
              >
                Our Goldendoodle Puppies
              </h3>
              <hr />
              <p>
                Are you ready to have a new family member? A new best friend?
                Someone that will love playing fetch, going on walks, and being
                there for you? Someone that will keep you company? Someone you
                can take cute selfies with?{" "}
              </p>
              <p>
                Our goldendoodles are raised in a family environment. Our
                puppies interact with humans since day 1. Our puppies interact
                with kids, teens, and adults. We are extremely excited to bring
                you your new family member! We want to share the joy of having a
                best friend with you!
              </p>
              <p className="pb-2">
                Check out our available puppies for your new best friend!
              </p>
              <Link to="/puppies">
                <button
                  type="button"
                  className="btn btn-primary text-uppercase btn-large btn-block mb-5"
                >
                  Reserve Your Puppy!
                </button>
              </Link>
            </div>
            <div className="col-sm-6">
              <Img fluid={data.file.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "girl-with-dog.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
